import React, { Component ,useReducer} from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import TimePicker from "rc-time-picker";
import Switch from "rc-switch";
import swal from "sweetalert2";
import moment from "moment";
import "sweetalert2/dist/sweetalert2.min.css";
import { Check } from "react-feather";
import configs from "../../../../../config/urlConfigs.js";
const readCookie = require("../../../../../cookie.js").readCookie;

export default class AdminDeclareModal extends Component {
  constructor(props) {
    super(props);
    const { participants, index } = props;
    const row = participants[index];
      this.state = {
      toggleValue:props.participants[props.index]['participated'] === "" ? 
      props.participants[props.index]['updatedParticipatedAttendence'] || false:
       (props.participants[props.index]['updatedParticipatedAttendence'] !== "" ?
       props.participants[props.index]['updatedParticipatedAttendence'] :
        props.participants[props.index]['participated'] || false )  ,
        
      
      
      // props.participants[props.index]["updatedParticipatedAttendence"] ||
      //   props.participants[props.index]["participated"] ||
      //   false,

      start_time: props.participants[props.index].hasOwnProperty("startTime") === true?  
      props.participants[props.index]["updatedStartTime"] || props.participants[props.index]["startTime"] 
       : props.participants[props.index]["updatedStartTime"] || props.selectedOpportunityData.startTime,
        end_time:props.participants[props.index].hasOwnProperty("endTime") === true
          ? props.participants[props.index]["updatedEndTime"] ||
          props.participants[props.index]["endTime"] : props.participants[props.index]["updatedEndTime"] || props.selectedOpportunityData.endTime,
            error: [],
      participants: props.participants,
      guestList: props.guestList,
      tableData: props.tableData,
      friendsList :[],
      friends: [{ id: 0 }],
      displayRegistrations: false,
      // currentIndex :0
    };
    this.addFriends = this.addFriends.bind(this);
    this.addFriends = this.addFriends.bind(this);
		this.deleterow = this.deleterow.bind(this);
		this.handleAddFriends = this.handleAddFriends.bind(this);
  }

  onChange(name, required, value) {
    if (name === "toggleValue") {
      this.setState({ toggleValue: !this.state.toggleValue });
    } else {
      var error = this.state.error;
      // var index = error.indexOf(name);
      if (name === "start_time" || name === "end_time") {
        if (required && !["-", "N/A"].includes(value));
        this.setState({ [name]: moment(value).format("HH:mm"), error });
      } else {
        if (required && value.target.value !== "");
        this.setState({ [name]: value.target.value, error });
      }
    }
  }

  hours(start_time, end_time) {
    var endTime = moment(end_time, "h:mm");
    var startTime = moment(start_time, "h:mm");
    var duration = moment.duration(endTime.diff(startTime));
    var hours = parseInt(duration.asHours());
    var minutes = parseInt(duration.asMinutes()) % 60;
    return hours + ":" + minutes;
  }

  async declare() {
    var error = [];
    if (this.state.toggleValue === true) {
      if (["", "N/A"].includes(this.state.start_time)) error.push("start_time");
      if (["", "N/A"].includes(this.state.end_time)) error.push("end_time");
      if (this.state.start_time && this.state.end_time) {
        let start_time_to_check = moment()
            .hour(this.state.start_time.split(":")[0])
            .minute(this.state.start_time.split(":")[1])
            .toDate(),
          end_time_to_check = moment()
            .hour(this.state.end_time.split(":")[0])
            .minute(this.state.end_time.split(":")[1])
            .toDate();

        
        if (start_time_to_check >= end_time_to_check) {
          error.push("start_time_error");
        }
      }
    }
    this.setState({ error });
    let data = {};
    data["voId"] = this.props.attendeeId;
    data["participated"] = this.state.toggleValue;
    if (this.state.toggleValue) {
      data["startTime"] = ["", "N/A"].includes(this.state.start_time)
        ? ""
        : this.state.start_time;
      data["endTime"] = ["", "N/A"].includes(this.state.end_time)
        ? ""
        : this.state.end_time;
    }

    let participantdata=this.state.participants
    if(this.props.multipleapprove){
      if(this.props.markAttendenceWithDecalredAttendance){
        const checkboxList = this.props.checkBoxList; // an array of objects with id and checked property

        participantdata = participantdata.map((participant, index) => {
          const checkbox = checkboxList.find((checkboxItem) => checkboxItem.id === participant.employeeId);
        console.log(participant.participated);
          // If checkbox is checked, update the participant data
          if ((this.props.selectAllList ||( checkbox && checkbox.checked))&& participant.participated ) {
            return {
              ...participant,
              updatedParticipatedAttendence: this.state.toggleValue,
              updatedStartTime: this.state.start_time,
              updatedEndTime: this.state.end_time,
              updatedHoursTime: this.hours(this.state.start_time, this.state.end_time),
              adminName: JSON.parse(readCookie("userData")).fullName,
              adminId: JSON.parse(readCookie("userData")).id,
            };
          }
          else{
            return {
              ...participant
            }
          }
        })
      }
      else{
        const checkboxList = this.props.checkBoxList; // an array of objects with id and checked property

        participantdata = participantdata.map((participant, index) => {
          const checkbox = checkboxList.find((checkboxItem) => checkboxItem.id === participant.employeeId);
        
          // If checkbox is checked, update the participant data
          if (this.props.selectAllList ||( checkbox && checkbox.checked)) {
            return {
              ...participant,
              updatedParticipatedAttendence: this.state.toggleValue,
              updatedStartTime: this.state.start_time,
              updatedEndTime: this.state.end_time,
              updatedHoursTime: this.hours(this.state.start_time, this.state.end_time),
              adminName: JSON.parse(readCookie("userData")).fullName,
              adminId: JSON.parse(readCookie("userData")).id,
            };
          }
          else{
            return {
              ...participant
            }
          }
        })
      }
     
    }
    else{

     participantdata[this.props.index] = {
        ...this.state.participants[this.props.index],
        updatedParticipatedAttendence: this.state.toggleValue,
        updatedStartTime: this.state.start_time,
        updatedEndTime: this.state.end_time,
        updatedHoursTime: this.hours(this.state.start_time, this.state.end_time),
        adminName: JSON.parse(readCookie("userData")).fullName,
        adminId: JSON.parse(readCookie("userData")).id,
      };
    }
    
    // console.log(participantdata,this.props.markAttendenceWithDecalredAttendance);

    

    if (error.length === 0) {
      let friendData = [];
      this.state.guestList.map((data) => {
          if (data.volunteerId === this.props.attendeeId){
            // friendData.push(data.friends)
            friendData= data.friends
          }
      })

      if(!this.state.multipleapprove){
      const guestData ={
            data : this.state.friendsList.length === 0 ? ( friendData && friendData.length > 0 &&
               this.state.friends.length > 0 && !this.state.friends[0].name  ? 
               ([...this.state.friends,...friendData] ): (friendData && friendData.length > 0 &&
                this.state.friends.length > 0 && !this.state.friends[0].name ?  friendData :( this.state.friends))) : 
               (!this.state.friends[0].name ? ([...this.state.friendsList,...this.state.guestList[0].friends]): 
                 [...this.state.friendsList,...this.state.friends,...this.state.guestList[0].friends]),
            adminName: JSON.parse(readCookie("userData")).fullName,
            adminId: JSON.parse(readCookie("userData")).id,
            updatedParticipatedAttendence:this.state.toggleValue,
            updatedStartTime :this.state.start_time,
            updatedEndTime : this.state.end_time
            }

            // console.log(guestData);
            try {
              const response = await fetch(configs.BACKEND_API_URL + "/ee/friends-list/total/" + this.props.selectedOppurtunityId, {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                  Auth: JSON.parse(readCookie("access_token")).access_token,
                },
                body: JSON.stringify(guestData),
              });
        
              const data = await response.json();
        
              if (data.status === "error") {
                swal({
                  title: data.message,
                  type: "error",
                  confirmButtonText: "Ok",
                }).then((result) => {
                  if (result) {
                    window.location.reload()
                  }
                }).catch(() => { });
                return; // Stop execution if the friends API fails
              }
            } catch (error) {
              swal({
                title: "Error connecting to the server. Please try again.",
                type: "error",
                confirmButtonText: "Ok",
              }).then((result) => {
                if (result) {
                  window.location.reload()
                }
              }).catch(() => { });
      
              return; // Stop execution if the fetch call fails
            }
    }
      fetch(
        configs.BACKEND_API_URL +
          "/ee/volunteer-opportunity/update-approval/" +
          this.props.selectedOppurtunityId,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Auth: JSON.parse(readCookie("access_token")).access_token,
          },
          body: JSON.stringify({ tableData: participantdata}),
        }
      )
        .then(function (response) {
          // this.props.hideAdminDeclareModal();
          return response.json();
        })
        .then(function (data) {
          // this.props.getAllData();


        });
      if (this.state.toggleValue === true) {
        swal(
          "Awesome!",
          "You have changed and approved the volunteer hours.",
          // +
            // this.hours(
            //   this.state.start_time ||
            //     this.props.participants[this.props.index]["startTime"],
            //   this.state.end_time ||
            //     this.props.participants[this.props.index]["endTime"]
            // ) +
            // " Time" +
            // " Attendance has been marked ",
          "success"
        ) .then(()=>{

          this.props.setParticipants(participantdata);
          this.props.hideAdminDeclareModal(); 
          this.props.fetchUpdateOpportunityDetails();
          this.props.aftersubmitdeclareModel()
           
        })
        // window.location.reload();
      } else if (this.state.toggleValue === false) {
        swal("Attendance declined!", "You have declined the volunteer hours.")
        .then(()=>{

          this.props.hideAdminDeclareModal();
          this.props.fetchUpdateOpportunityDetails();
          this.props.aftersubmitdeclareModel()
        })
        // window.location.reload();
      }
    }
  }
  
  handleFriendsAttended(index) {
    this.state.guestList.map((data) => {
      if (data.volunteerId === this.props.attendeeId) {
        if (data.friends[index].hasOwnProperty('attended')  ){
          data.friends[index].attended = !data.friends[index].attended
        } else {
          data.friends[index]['attended'] = true
        }
 this.setState({friendsList:data.friends})
}});

}



handleFriendsNameChange(index,e){

  this.state.guestList.map((data) => {
  if (data.volunteerId === this.props.attendeeId) {
  data.friends[index].name = e.target.value;
  this.setState({friendsList:data.friends})
  
  }});
  // this.setState({friendsList :friends}) 
}

friendsDetailsAdded() {
  const index = this.state.friends.length - 1;
  // this.setState({currentIndex:index});

  if (this.state?.friends[index]?.name && this.state?.friends[index]?.gender && this.state?.friends[index]?.age) {
       this.state.friends[index]['refferedBy'] = this.props.attendeeId;
       this.state.friends[index]['opportunityId'] = this.props.selectedOppurtunityId;

    return true
  } else return false
}

addFriends() {
  const data = this.state.friends.filter(x => !x._id);
  if (this.friendsDetailsAdded()) {
        var addRow = [];
        addRow = this.state.friends;
        addRow.push({ id: this.state.friends.length });
        this.setState({friends:addRow});
  }
//   if (this.friendsDetailsAdded()) {
//     var addRow = [];
//     addRow = this.state.friends;
//     addRow.push({ id: this.state.friends.length });
//     this.setState({friends:addRow});
//         fetch(configs.BACKEND_API_URL + '/ee/add-friends/' + this.props.selectedOppurtunityId + '/' + this.props.attendeeId,
//             {
//                 method: "POST",
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Auth': JSON.parse(readCookie('access_token')).access_token
//                 },
//                 body: JSON.stringify(data)
//             }).then(function (response) {
//                 return response.json();
//             }).then(function (data) {
//                 if (data.status === 'ok') {
//                   console.log("data:",data);
//                   // this.state.friends[this.state.currentIndex]._id = data.friendId
// // this.state.friends
//                 // addRow.push({_id:data.friendId});
//                 // this.setState({friends:addRow});

//                 // this.setState({friends:addRow});
//                     swal("Great", "List Updated!", "success")
//                         .then(function () {
//                         })
//                         // .catch(function () {
//                         // });
//                 } else {
//                     swal("Oops...", "Something went wrong!", "error");
//                 }
//             }.bind(this))
//           }
}
deleterow(id, e,_id) {
  // console.log(_id,"Idd")
  
  if (id === 0) {
    // console.log("friendsDetails =========>>>>>>",this.state.friends[id]); 
    this.setState({ displayRegistrations: false, friends: [{ id: 0 }] })
  }
  else {
    var delrow = [{ id: 0 }];
    delrow = this.state.friends;
    delrow.splice(id, 1)
    this.setState({ friends: delrow })
  }
}
handleAddFriends(index, value, key) {
  let commentRegex = new RegExp(`^[\.a-zA-Z0-9,!?:;@#$~' ]*$`);
  if (commentRegex.test(value) === true || true){
  if (value) {
    var friends = this.state.friends;
    friends[index][key] = value
    this.setState({ friends });
  }
}
}




handleAddedNewFriendsAttended(index) {
  var friends = this.state.friends;
  // friends[index][key] = value
  // this.setState({ friends });

    if (this.props.attendeeId) {
      if (friends[index].hasOwnProperty('attended')  ){
        this.state.friends[index].attended = !this.state.friends[index].attended
      } else {
        friends[index]['attended'] = true
      }
this.setState({ friends })
}


}

  render() {
    return (
      <Modal
        bsSize="large"
        className="declare-modal"
        show={this.props.showDeclareModalVisibility}
        onHide={this.props.hideAdminDeclareModal}
      >
        <Modal.Header closeButton>
          <Modal.Title className="modal-header-custom">
            Declare Attendance
          </Modal.Title>
        </Modal.Header>
 
        <Modal.Body>
          <Row>
            <Col className="margin-col-50" md={12}>
              <form id="edit-org-details-modal-form"
                // ref="vForm"
              >
                <Col md={12}>
                  <div className="ee-recommend-switch-container">
                    <span className="ee-recommend-switch-left-text">
                      Missed this opportunity
                    </span>
                    <Switch
                      onChange={this.onChange.bind(this, "toggleValue")}
                      disabled={this.state.disabled}
                      defaultChecked={this.state.toggleValue}
                      checked={this.state.toggleValue}
                    />
                    <span className="ee-recommend-switch-right-text">
                      Took part in this opportunity
                    </span>
                  </div>
                </Col>

                {this.state.toggleValue ? (
                  <Col md={12}>
                    <Col className="form-group" md={3} mdOffset={3}>
                      <label className="control-label is-imp">
                        Start Time:{" "}
                      </label>
                      <TimePicker 
                      // use12Hours={true}
                       defaultValue={ this.state.start_time !=="" ? moment()
                                .hour(this.state.start_time.split(":")[0])
                                .minute(this.state.start_time.split(":")[1]): ""  
                        } className="form-control"  showSecond={false}
                        onChange={this.onChange.bind(this, "start_time", true)}
                      />
                      {this.state.error.indexOf("start_time") > -1 ? (
                        <span className="red-color">Incorrect Time!</span>
                      ) : null}
                      {this.state.error.indexOf("start_time_error") > -1 ? (
                        <span className="red-color">Incorrect Time!</span>
                      ) : null}
                    </Col>

                    <Col className="form-group" md={3}>
                      <label className="control-label is-imp">End Time: </label>

                      <TimePicker 
                      // use12Hours={true}
                        defaultValue={ this.state.end_time !=="" ? moment()
                                .hour(this.state.end_time.split(":")[0])
                                .minute(this.state.end_time.split(":")[1]) : "" }
                                               className="form-control"
                        showSecond={false} onChange={this.onChange.bind(this, "end_time", true)}
                      />
                      {this.state.error.indexOf("end_time") > -1 ? (
                        <span className="red-color">Incorrect Time!</span>
                      ) : null}
                      {this.state.error.indexOf("end_time_error") > -1 ? (
                        <span className="red-color">Incorrect Time!</span>
                      ) : null}
                    </Col>
                    { !this.props.multipleapprove && this.state.tableData.hasOwnProperty("registrationsAllowed")
                      ? this.state.guestList.map((data) => {
                          if (data.volunteerId === this.props.attendeeId) {
                            return (
                              <div style={{ marginBottom: 20 }}>
                                {data.friends.length > 0 ? <><Row>
                                  <Col md={6} sm={6} mdOffset={3}>
                                    <label
                                      className="control-label"
                                      style={{
                                        fontWeight: "bold",
                                        textDecoration: "underline",
                                      }}
                                      htmlFor="friendsName"
                                    >
                                      Check the boxes against your guests who attended the event.
                                    </label>
                                  </Col>
                                </Row><Row><Col md={6} sm={6} mdOffset={3}><note>Names will appear on the certificate as above. Correct the names if necessary.</note></Col></Row></>:null}
                                {data.friends.map((friend, index) => (
                                  <Row>
                                    <Col md={5} sm={5} xs={8} mdOffset={3}>
                                      <div className="form-group pb0 mb0">
                                        <input
                                          type="text"
                                          className="form-control"
                                          style={{ margin: 0 }}
                                          required
                                          value={friend.name}
                                          onChange={this.handleFriendsNameChange.bind(this,index)}
                                        />
                                      </div>
                                    </Col>
                                    <Col md={1} sm={1} xs={1} style={{ padding: 0 }}>
                                      <div
                                        class="form-group form-check checkbox-row"
                                        style={{ margin: 0 }}
                                      >
                                        <a
                                          className={ friend.attended  ? "checkbox-active mt10" : "checkbox-inactive mt10" }
                                          onClick={() =>
                                            this.handleFriendsAttended(index)
                                          }
                                        >
                                          <Check color="white" style={{ alignSelf: "center" }} strokeWidth={3} size={12} />
                                        </a>
                                      </div>
                                    </Col>
                                  </Row>
                                ))}
                              </div>
                            );
                          }
                        })
                      : null}
                      <Row>
                      <Col md={6} sm={6} xs={8} mdOffset={3}>

                      

                { !this.props.multipleapprove && 
								this.props.maxVolunteer >= this.props.totalAttendee + this.props.totalGuest ?
									<div className="form-group mb-0">
										<input type="checkbox" className="" id="displayRegistrations" 
                    checked={this.state.displayRegistrations} required 
                    onChange={() => this.setState({ displayRegistrations: !this.state.displayRegistrations })}  
                    />
										<label className="checkbox-label ml10" htmlFor='displayRegistrations'>Add friend(s)/family member(s) to this event.</label>
										</div> 
                    :null
                    // <note>We are sorry! There are no slots available to reserve for your friends/family members.</note>
								}
                	{this.state.displayRegistrations && <>
									<Row style={{ margin: 0 }}>
										<Col md={4} sm={4} xs={4} style={{ padding: 0 }}>
											<label className="control-label is-imp ml5" style={{ fontWeight: 'bold' }} htmlFor='friendsName' >Name</label>
										</Col>
										<Col md={3} sm={6} xs={3} style={{ padding: 0 }}>
											<label className="control-label is-imp ml5" style={{ fontWeight: 'bold' }} htmlFor='friendsGender' >Gender</label>
										</Col>
										<Col md={3} xs={3} sm={6} style={{ padding: 0 }}>
											<label className="control-label is-imp ml5" style={{ fontWeight: 'bold' }} htmlFor='friendsName' >Age</label>
										</Col>
										{this.state.friends.map((friend, index) => <>
											<Col md={4} sm={4} xs={4} style={{ padding: 0 }}>
												<div className="form-group pb0 mb0" >
													<input type="text" className="form-control" required value={friend.name} onChange={(e) => this.handleAddFriends(index, e.target.value, 'name')} />
												</div>
											</Col>
											<Col md={3} sm={3} xs={3} style={{ padding: 0 }}>
												<div className="form-group pb0 mb0">
													<select className="form-control" required value={friend.gender} 
                          onChange={(e) => this.handleAddFriends(index, e.target.value, 'gender')}>
														<option selected disabled>Gender</option>
														<option value="Male">Male</option>
														<option value="Female">Female</option>
														<option value="Prefer not to say">Prefer not to say</option>
													</select>
												</div>
											</Col>
											<Col md={2} sm={2} xs={2} style={{ padding: 0 }}>
												<div className="form-group pb0 mb0">
													<input
														type="number"
														className="form-control"
														required
														value={friend.age}
														max={80} min={5}
														onChange={(e) => (e.target.value > 4 && e.target.value < 81) && this.handleAddFriends(index, parseInt(e.target.value), 'age')}
													/>
												</div>
											</Col>
                      <Col md={1} sm={1} xs={1} style={{ padding: 0,marginLeft:5 }}>
                                      <div
                                        class="form-group form-check checkbox-row"
                                        style={{ marginLeft:'20px'  }}
                                      >
                                        <a
                                          className={ friend.attended  ? "checkbox-active mt10" : "checkbox-inactive mt10" }
                                          onClick={() =>
                                            this.handleAddedNewFriendsAttended(index)
                                          }
                                        >
                                          <Check color="white" style={{ alignSelf: "center" }} strokeWidth={3} size={12} />
                                        </a>
                                      </div>
                                    </Col>
											{/* <Col md={1} sm={1} xs={1} style={{ padding: 0 }}>
												<div className="form-group pb0 mb0">
													<button className='btn btn-primary' style={{ marginLeft: 40 }} onClick={() => this.deleterow(index)}>
														<i className="fa fa-trash"></i>
													</button>
												</div>
											</Col> */}
										</>)}
									</Row>

									{ this.props.totalAttendee + this.props.totalGuest+ this.state.friends.length < this.props.maxVolunteer  ? <div align='right' className='mt10'>
										<button className={`btn ${this.friendsDetailsAdded() ? 'btn-primary' : 'btn-primary-disabled'} btn-sm`} onClick={this.addFriends}>
											<i className="fa fa-plus"></i> add
										</button>
                  </div>                
                  :null}
								</>} 
                </Col>
                </Row>
                  </Col>
                ) : null}
              </form>
            </Col>
          </Row>
        
        </Modal.Body>
        <Modal.Footer className="footer-center">
          <Button className="btn-sq btn-edit" onClick={this.declare.bind(this)}>
            Submit{" "}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
